import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VoucherPayphoneComponent, VoucherPayphoneService } from "@angular-monorepo/voucher-payphone"

@Component({
    selector: 'angular-monorepo-voucher-payphone-wrapper',
    standalone: true,
    imports: [CommonModule, VoucherPayphoneComponent],
    templateUrl: './voucher-payphone-wrapper.component.html',
    styleUrl: './voucher-payphone-wrapper.component.scss',
    providers: [VoucherPayphoneService]
})
export class VoucherPayphoneWrapperComponent { }
