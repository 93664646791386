import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { VoucherPayphoneService } from './voucher-payphone.service';
import { HttpClientModule } from '@angular/common/http';

@Component({
  selector: 'angular-monorepo-voucher-payphone',
  standalone: true,
  imports: [CommonModule, HttpClientModule],
  templateUrl: './voucher-payphone.component.html',
  styleUrl: './voucher-payphone.component.scss',
})
export class VoucherPayphoneComponent implements OnInit, OnDestroy {
  private readonly subscriptions = new Subscription()
  method: string;
  transactionId: string;
  verification: string;

  constructor(private renderer: Renderer2, private readonly activatedRoute: ActivatedRoute, private readonly voucherPayphoneService: VoucherPayphoneService) { }

  ngOnInit(): void {
    this.subscriptions.add(
      this.activatedRoute.params.subscribe(params => {
        this.method = params['method'];
        this.transactionId = params['transactionId'];

        this.subscriptions.add(
          this.activatedRoute.queryParams.subscribe(params => {
            this.verification = params['verification'];

            this.subscriptions.add(
              this.voucherPayphoneService.getOperation(this.transactionId!, this.verification!).subscribe(response => {
                const amount = Number(response.operation.amount) * 100;
                const tokenRef = response.attributes.find((a: any) => a.code === "token");
                const tokenValue = JSON.parse(tokenRef.value);
                const documentoRef = response.customer.documentType;
                let tipoDocumento: number;
                switch (documentoRef) {
                  case "CI":
                    tipoDocumento = 1;
                    break;

                  case "PP":
                    tipoDocumento = 3;
                    break;

                  case "PAS":
                    tipoDocumento = 3;
                    break;

                  case "RUC":
                    tipoDocumento = 2;
                    break;

                  default:
                    tipoDocumento = 1;
                    break;
                }

                setTimeout(() => {
                  new (<any>window).PPaymentButtonBox({
                    token: tokenValue.token,
                    amount: amount,
                    amountWithoutTax: amount,
                    clientTransactionId: response.operation.id,
                    showCardPayment: this.method === "card",
                    showPayphonePayment: this.method === "wlt",
                    lang: "es",
                    email: response.customer.email,
                    documentId: response.customer.document,
                    identificationType: tipoDocumento,
                    currency: response.operation.currencyCode
                  }).render('pp-button');
                }, 1000);
              })
            )
          })
        )

      })
    )
  }

  ngOnDestroy(): void {
  }

  ngAfterViewInit() {
    this.loadScript('https://cdn.payphonetodoesposible.com/box/v1.1/payphone-payment-box.js');
    this.loadStylesheet('https://cdn.payphonetodoesposible.com/box/v1.1/payphone-payment-box.css');
  }

  private loadScript(src: string) {
    if (!document.querySelector(`script[src="${src}"]`)) {
      const script = this.renderer.createElement('script');
      script.type = 'module';
      script.src = src;
      script.onload = () => console.log('PayPhone script loaded');
      this.renderer.appendChild(document.body, script);
    }
  }

  private loadStylesheet(href: string) {
    if (!document.querySelector(`link[href="${href}"]`)) {
      const link = this.renderer.createElement('link');
      link.rel = 'stylesheet';
      link.href = href;
      this.renderer.appendChild(document.head, link);
    }
  }

}
