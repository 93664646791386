<div class="container">
  <div class="steps" [ngClass]="column ? 'column' : ''">
    @for (step of instruccionStepperInput; track $index) {
    <div class="step-container">
      <div class="step"  
      [style.background-color]="(esResponsive$ | async) ? '' : getStepBackgroundColor()"
      [ngClass]="{'step-1': instruccionStepperInput[0] == step && color !== 'secondary' , 'step-4': instruccionStepperInput[3] == step }">
        <span [style.color]="(esResponsive$ | async) ?getStepColor() : '' ">{{step.step}} {{ (esResponsive$ | async) ? '.' : ''}}</span>
      </div>
      @if (step?.title) {
      <div class="title" >
        <span [innerHTML]="step.title"></span>
      </div>
      }
      @if(step?.description){
      <div class="description">
        <span [innerHTML]="step.description"></span>
      </div>
      }
    </div>
    @if ($index < instruccionStepperInput.length - 1) { <div class="line-container">
      <div class="line"></div>
  </div> }
  }
</div>
</div>
