import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReadyButton } from './ready-button.interface';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'sh-ready-button',
  standalone: true,
  imports: [CommonModule, MatButtonModule,],
  templateUrl: './ready-button.component.html',
  styleUrl: './ready-button.component.scss',
})
export class ReadyButtonComponent {
  @Input({ required: true }) readyButtonInput: ReadyButton;

  get label(): string {
    return this.readyButtonInput.label;
  }
}
