<div class="container">
    <div class="title">
      @if(title) {
      <h2>{{title}}</h2>
      }
    </div>
    @if (linearSteps.length > 0) {
   <div class="steps">
      <sh-pv-qr-step-info [voucherData]="voucherData" [linearSteps]="linearSteps" [layout]="'qr'" ></sh-pv-qr-step-info>
    </div>
    }
  </div>