import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Banner, BannerComponent, InstructionStepperComponent, LinearStepInfo } from '@angular-monorepo/shared';
import { VoucherTemplateInput } from './voucher-template.interface';
import { VoucherHeaderComponent } from 'libs/direct-connections-voucher/src/lib/components/voucher-header/voucher-header.component';
import { PvDefaultHeaderComponent } from 'libs/checkout-payin-voucher/src/lib/components/default-voucher/pv-default-header/pv-default-header.component';
import { Processors, ProcessorStaticViewComponent } from '@angular-monorepo/processor-selection';
import { PvQrHeaderComponent } from 'libs/checkout-payin-voucher/src/lib/components/qr-voucher/pv-qr-header/pv-qr-header.component';

@Component({
  selector: 'pdf-voucher-template',
  standalone: true,
  imports: [CommonModule, InstructionStepperComponent, VoucherHeaderComponent, BannerComponent,
    PvDefaultHeaderComponent,ProcessorStaticViewComponent,PvQrHeaderComponent],
  templateUrl: './voucher-template.component.html',
  styleUrl: './voucher-template.component.scss',
})
export class VoucherTemplateComponent implements OnChanges {
  @Input({ required: true }) data: VoucherTemplateInput = {} as VoucherTemplateInput
  @Input() voucherPayin:boolean = false;
  @Input() layout: string = 'default'
  @Input() voucherData:any = null;
  @Input() processors:Processors[] = [];
  @Input() copy = false;
  @Input() color:string;
  linearStepInfo: LinearStepInfo[] = []

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data']) {
      this.data = changes['data'].currentValue;
      this.linearStepInfo = this.data.LinearStepInfo;
    }
  }

  parseBanner(bank: string, hexColor: string): Banner {
    if(this.voucherData && this.voucherData.processor && this.voucherData.processor.parent == 'Deuna'){
     return {
        processor: '',
        text: 'Estás pagando',
       img: `./assets/svg/deuna.svg`,
       background:  '#442E4C',
      }
    }else{
      return {
        processor: bank,
        text: `${bank}`,
        // img: `https://monnet-cash-config.s3.amazonaws.com/banks/${bank}/banner.svg`,
        background: hexColor || '',
      };
    }
  }

  getStepColor(){
    return this.color == 'secondary' ? '#6A00F4' : '#395ae8';
  }
}
