import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';


@Component({
  selector: 'download-voucher-form-yape',
  standalone: true,
  imports: [CommonModule, MatFormFieldModule,
    MatButtonModule,
    MatIconModule,
    MatToolbarModule,],
  templateUrl: './download-voucher-form-yape.component.html',
  styleUrl: './download-voucher-form-yape.component.scss',
})
export class DownloadVoucherFormYapeComponent {
  @Input() operationInfoStatus:any
  @Input() operationInfo:any

}
