import { EnvironmentService } from '@angular-monorepo/environment';
import { Injectable, WritableSignal, computed, signal } from '@angular/core';
import { catchError, take, tap } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { State } from '../../interfaces';
import { GetPaymentPayloadVoucherRequest } from './payin-voucher.interface';

@Injectable({
  providedIn: 'root'
})
export class VoucherPayinService {
  #getPaymentData: WritableSignal<State<any>> = signal<State<any>>({ data: {} as any, loading: false })

  public paymentData = computed(() => this.#getPaymentData().data)
  public paymentLoading = computed(() => this.#getPaymentData().loading)
  public getPaymentError = computed(() => this.#getPaymentData().error)

  constructor(
    private environmentService: EnvironmentService,
    private http: HttpClient,
  ) { }

  getVoucherDetails(params: GetPaymentPayloadVoucherRequest): void {
    const headers = new HttpHeaders({
      'verification':  params.verification
    });
    this.#getPaymentData.set({ data: {} as any, loading: true })
    this.http.post<any>(`${this.environmentService?.env?.apiConfig?.payinCheckoutBackendUrl}common/operation/find/voucher/${params.operationId}`,{},{headers})
      .pipe(
        take(1),
        tap((response: any) => {
          this.#getPaymentData.set({ data: response, loading: false })
        }),
        catchError((error) => {
          this.#getPaymentData.set({ data: {} as any, loading: false, error: error })
          throw error;
        })
      ).subscribe()
  }
}


