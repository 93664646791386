<div class="container" [style.background-color]="getBackgroundColor('#ECE5FF', '')"> 
  @for (step of linearSteps; track $index) {
  <div class="step" [ngClass]="$index === 0 ? 'w-100' : 'w-50'">
    @if(step.icon) {
    <div class="step-icon">
      <img [src]="step.icon" alt="">
    </div>
    }

    <div class="label" [ngClass]="step.label.fontSize">
      <span class="title" [innerHTML]="step.label.label" [ngStyle]="parseStyleString(step?.label?.labelStyle)"></span>
      <div class="value">
        <span [innerHTML]="step.label.value" [ngClass]="step.label.color"
          [ngStyle]="parseStyleString(step?.label?.valueStyle)"></span>

        @if (step.clipboard && clipboard) {
        <div class="clipboard">
          <button mat-flat-button (click)="copyToClipboard(step.clipboardText)" [style.background-color]="getButtonColor()">
            <img src="./assets/svg/clipboard.svg" alt="" />
            <span>Copiar</span>
          </button>
        </div>
        }
      </div>

      <p *ngIf="step?.label?.sublabel" [innerHTML]="step?.label?.sublabel?.text"
        [ngStyle]="parseStyleString(step?.label?.sublabel?.style)" >
    </p>


    </div>
    @if (step.status) {
    <div class="status">
      <span [innerHTML]="step.status.label"></span>
    </div>
    }
  </div>
  <!-- LINEAR STEPS -->
  @if ($index < linearSteps.length - 1) { <div class="line-container">
    <div class="line" [style.background-color]="getLineColor()"></div>
</div> }
}
</div>