import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckoutFormCoinnangDebinComponent } from '@angular-monorepo/checkout-form-coinnang-debin';
import { CheckoutFormCoinnangDebinService } from '@angular-monorepo/checkout-form-coinnang-debin';

@Component({
    selector: 'angular-monorepo-form-niubiz-yape',
    standalone: true,
    imports: [CommonModule, CheckoutFormCoinnangDebinComponent],
    templateUrl: './form-coinnang-debin.component.html',
    styleUrl: './form-coinnang-debin.component.scss',
    providers: [CheckoutFormCoinnangDebinService]
})
export class FormCoinnangDebinComponent { }
