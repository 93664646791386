<article >
    <div class="container">
        <div class="container__icono">
            <mat-icon>info</mat-icon>
          </div>
          <p class="container__titulo" [innerHTML]="title"></p>
          <p class="container__subtitulo" [innerHTML]="subtitle"></p>

          <img class="container__imagen" src="./assets/svg/monnet.svg" alt="clining-error">
    </div>
  
  </article>