import { Component, ElementRef, Input, SimpleChanges, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LinearStepInfo } from '../linear-step-info';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { MediaButtonComponent } from '@angular-monorepo/ui';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { CaptureScreenService } from '@angular-monorepo/screenshot';
import { ResponsiveService } from 'libs/shared/src/lib/services/responsive.service';
import { HttpClient } from '@angular/common/http';
import { TimerService } from '../../services/timer.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'sh-pv-default-linear-step-info',
  standalone: true,
  providers: [ResponsiveService],
  imports: [CommonModule,MediaButtonComponent,MatButtonModule,MatIconModule,MatSnackBarModule],
  templateUrl: './pv-default-linear-step-info.component.html',
  styleUrl: './pv-default-linear-step-info.component.scss',
})
export class PvDefaultLinearStepInfoComponent {
  @Input() linearSteps: LinearStepInfo[] = [];
  @Input() layout: 'qr' | 'code' | 'default' = 'default';
  @Input() copy = true;
  @Input() voucherData: any;

  base64QRCode: string;
  @ViewChild('contenedor', { static: true }) contenedor: ElementRef;
  hidden: boolean = false;
  timerString: string;
   classString: string;
   titleTimer:string;

   timerSubscription: Subscription;
   classSubscription: Subscription;
   titleTimerSubscription: Subscription;
   isDeuna: boolean = false;
   titleDeuna: string = 'Haz click a <strong>PAGAR</strong> para redirigirte <br> automáticamente a tu app de DEUNA.';
  constructor(
    public  screenRecordService: CaptureScreenService,
    private snackBar: MatSnackBar,
    private timerService: TimerService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['linearSteps']) {
      this.linearSteps = changes['linearSteps'].currentValue;
    }
 
    if(this.layout== 'qr' || this.layout == 'code'){
      this.removeWhiteBackground(this.linearSteps[0].label.value);
    }

  }

  ngOnInit(): void {
    this.setDeuna();
  }

  setDeuna(){
    this.isDeuna = this.voucherData && this.voucherData.processor.parent == 'Deuna';
    if(this.isDeuna){
   
      if(this.voucherData?.operation?.status == '5'){
        this.timerString = '<b>Pago exitoso</b>'
        this.classString = 'green';
        this.titleTimer = 'Estado';
      }else if (this.voucherData?.operation?.status == '3'){
        this.timerString = '<b>Pago expirado</b>'
        this.classString = 'red'
        this.titleTimer = 'Estado';
      }else{
        this.timerService.startTimer(this.voucherData.voucherData.expirationDate);
        this.timerSubscription = this.timerService.timerString$.subscribe(timer => {
          
          this.timerString = timer;
        });
        this.classSubscription = this.timerService.classString$.subscribe(classStr => {
          this.classString = classStr;
        });

        this.titleTimerSubscription = this.timerService.titleTimer$.subscribe(title => {
          
          this.titleTimer = title;
        } );
      }
      
      
    }
  }
 
  removeWhiteBackground(base64: string) {
  
    const image = new Image();
    image.src =  base64;
  
    image.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;

      canvas.width = image.width;
      canvas.height = image.height;

      ctx.drawImage(image, 0, 0);


      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      const data = imageData.data;

     
      for (let i = 0; i < data.length; i += 4) {
        if (data[i] === 255 && data[i + 1] === 255 && data[i + 2] === 255) {
         
          data[i + 3] = 0;
        }
      }

     
      ctx.putImageData(imageData, 0, 0);

      
      this.base64QRCode = canvas.toDataURL('image/png');
    };
  }

  copyToClipboard(){
    navigator.clipboard.writeText(this.voucherData.voucherData.paymentCode).then(() => {
      this.snackBar.open('Copiado en portapapeles', '', {
        duration: 1000,
      });
    });
  }

  ngOnDestroy(): void {
    if(this.timerSubscription){
      this.timerSubscription.unsubscribe();
    }
    if(this.classSubscription){
      this.classSubscription.unsubscribe();
    }

    if(this.titleTimerSubscription){
      this.titleTimerSubscription.unsubscribe();
    }
  }
}
