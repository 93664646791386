import { Injectable } from '@angular/core';
import { Environment } from '../environments';

@Injectable({ providedIn: 'root' })
export class EnvironmentService {
  private config: Environment;

  setConfig(config: Environment) {
    this.config = config;
  }

  get env(): Environment {
    return this.config;
  }
}
