import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageTemplateComponent } from '@angular-monorepo/ui';

@Component({
  selector: 'sh-not-found',
  standalone: true,
  imports: [CommonModule, PageTemplateComponent],
  templateUrl: './not-found.component.html',
  styleUrl: './not-found.component.scss',
})
export class NotFoundPageComponent { }
