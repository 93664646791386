import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogRef, } from '@angular/material/dialog';

@Component({
  selector: 'angular-monorepo-checkout-coinnang-modal-error',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './checkout-coinnang-modal-error.component.html',
  styleUrl: './checkout-coinnang-modal-error.component.scss',
})
export class CheckoutCoinnangModalComponent implements OnInit {

  constructor(public matDialogRef: MatDialogRef<CheckoutCoinnangModalComponent>) { }

  ngOnInit(): void {
  }

  onCancel() {
    this.matDialogRef.close();
  }
}
