import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckoutFormCoinnangDebinService } from './checkout-form-coinnang-debin.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { HttpClientModule } from '@angular/common/http';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { GetOperationResponse, ICreateDebinResponse, IGetOperationResponse } from './get-operation.response';
import { MatDialog } from '@angular/material/dialog';
import moment from 'moment';
import { CheckoutCoinnangModalComponent } from '../checkout-coinnang-modal-error/checkout-coinnang-modal-error.component';
import { CaptureScreenService } from '@angular-monorepo/screenshot';
import { PdfJsService } from '@angular-monorepo/pdf';

@Component({
  selector: 'angular-monorepo-checkout-form-coinnang-debin',
  standalone: true,
  imports: [CommonModule, MatFormFieldModule,
    HttpClientModule, MatIconModule, ReactiveFormsModule],
  templateUrl: './checkout-form-coinnang-debin.component.html',
  styleUrl: './checkout-form-coinnang-debin.component.scss',
})
export class CheckoutFormCoinnangDebinComponent implements OnInit, OnDestroy {
  private readonly subscriptions = new Subscription();

  verification: string | null;
  transactionId: string | null
  checkoutForm: FormGroup = this.fb.group({
    alias: [{ value: undefined, disabled: false }, Validators.required],
    nombre: [{ value: undefined, disabled: true }, Validators.required],
    CBU: [{ value: undefined, disabled: true }, Validators.required],
    CUIT: [{ value: undefined, disabled: true }, Validators.required]
  });
  operation: IGetOperationResponse;
  debin: ICreateDebinResponse | undefined = undefined;
  hasData: boolean = false;

  screenRecordService = inject(CaptureScreenService)
  jsPdfService = inject(PdfJsService)
  capturando: boolean = false;

  constructor(private _matDialog: MatDialog, private readonly checkoutFormCoinnangDebinService: CheckoutFormCoinnangDebinService, private readonly activatedRoute: ActivatedRoute, private readonly fb: FormBuilder) { }

  ngOnInit(): void {
    this.subscriptions.add(
      this.activatedRoute.params.subscribe(params => {
        this.transactionId = params['transactionId'];

        this.subscriptions.add(
          this.activatedRoute.queryParams.subscribe(params => {
            this.verification = params['verification'];

            this.subscriptions.add(
              this.checkoutFormCoinnangDebinService.getOperation(this.transactionId!, this.verification!).subscribe(response => {
                this.operation = new GetOperationResponse(response)
                this.startCountdown(this.operation.operation.expirationDate);
              })
            )
          })
        )

      })
    )
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onConsultarAlias() {
    if (!this.checkoutForm.value.alias) return;

    this.checkoutFormCoinnangDebinService.consultAlias(this.transactionId!, this.checkoutForm.value.alias, this.operation.verificationResult!).subscribe(response => {
      this.checkoutForm.patchValue({
        nombre: response.name,
        CBU: response.cbu,
        CUIT: response.cuit
      })

      this.hasData = true;
    });
  }

  onPay() {
    this.checkoutFormCoinnangDebinService.createDebin(this.transactionId!, this.operation.verificationResult).subscribe(response => {
      this.debin = response;
    })
  }

  countdownTime: string = '00:00:00';

  private startCountdown(targetDate: string): void {
    const target = moment(targetDate);

    const intervalId = setInterval(() => {
      const now = moment();
      const diff = target.diff(now);

      if (diff <= 0) {
        clearInterval(intervalId);
        this.countdownTime = '00:00:00';

        const modal = this._matDialog.open(CheckoutCoinnangModalComponent, {
          disableClose: true,
          width: "320px",
          height: "272px"
        });

        modal.afterClosed().subscribe(() => {
          window.open(this.operation.operation.errorUrl, '_self')
        })
      } else {
        const duration = moment.duration(diff);
        const formattedTime = moment.utc(duration.asMilliseconds()).format('HH:mm:ss');
        this.countdownTime = formattedTime;
      }
    }, 1000);
  }

  onPrint() {
    this.capturando = true;
    setTimeout(() => {
      this.screenRecordService.captureScreen("coinnang-debin-comprobante")

      this.screenRecordService.generatedImage$.subscribe(base64 => {
        const link = document.createElement("a");
        link.href = base64;
        link.download = "coinnang-debin";
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        this.capturando = false;

      })
    }, 0);

  }
}
