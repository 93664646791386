<div class="container">
  <ui-page-template [bgBlue]="true" [showFooter]="true">
    <div class="container" slot="content">
      <div class="img-loading">
        <img src="../../../../../assets/svg/error.svg" alt="">
      </div>
      <div class="info">
        <h2 class="message">Ha ocurrido un error</h2>
        <p class="sub-message">
          Ha ocurrido un error, vuelva a intentarlo más tarde...
        </p>
      </div>
    </div>
  </ui-page-template>
</div>
