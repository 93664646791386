import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LinearStepInfo } from './linear-step-info.interfaces';
import { MatButtonModule } from '@angular/material/button';
import {
  MatSnackBar,
} from '@angular/material/snack-bar';

@Component({
  selector: 'sh-linear-step-info',
  standalone: true,
  imports: [CommonModule, MatButtonModule],
  templateUrl: './linear-step-info.component.html',
  styleUrl: './linear-step-info.component.scss',
  
})
export class LinearStepInfoComponent implements OnChanges {
  @Input() linearSteps: LinearStepInfo[] = [];
  @Input() clipboard: boolean = true
  @Input() color: string
  @Input() secondaryColor:string;
  constructor(
    private alertService: MatSnackBar
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['linearSteps']) {
      this.linearSteps = changes['linearSteps'].currentValue;
    }
  }

  copyToClipboard(text: string | any): void {
    if (!text) {
      return
    }

    navigator.clipboard.writeText(text).then(() => {
      this.alertService.open('Copiado al portapapeles', '', {
        duration: 2000,
      });
    }, (err) => {
      console.error('Error al copiar texto: ', err);
    }
    );
  }

  getBackgroundColor(defaultColor: string, primaryColor: string): string {
    return this.color === 'primary' || !this.color ? primaryColor : defaultColor;
  }

  getButtonColor(): string {
    return this.color === 'primary' ? '#395ae8' : '#6A00F4';
  }

  getLineColor(): string {
    return this.color === 'primary' ? '#C8D5DF' : '#4E2CD1';
  }
  
  parseStyleString(style: string | undefined): { [key: string]: string } {
    if (!style) {
      return {};
    }
    return style.split(';').reduce((styles: { [key: string]: string }, stylePair) => {
      const [property, value] = stylePair.split(':');
      if (property && value) {
        styles[property.trim()] = value.trim();
      }
      return styles;
    }, {});
  }
}
