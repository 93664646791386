import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";
import { map, shareReplay } from "rxjs/operators";

@Injectable({
providedIn: "root"
})
export class ResponsiveService {
	esResponsive$: Observable<boolean> = this.breakpointObserver.observe(["(max-width: 480px)"]).pipe(
		map((result: BreakpointState) => result.matches),
		shareReplay(1)
	);

	esMaxResponsive$: Observable<boolean> = this.breakpointObserver.observe(["(max-width: 860px)"]).pipe(
		map((result: BreakpointState) => result.matches),
		shareReplay(1)
	);

	constructor(private breakpointObserver: BreakpointObserver) {}
}
