import { Component, Input, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LinearStepInfo, PvDefaultLinearStepInfoComponent } from '@angular-monorepo/shared';

@Component({
  selector: 'pv-default-header',
  standalone: true,
  imports: [CommonModule,PvDefaultLinearStepInfoComponent],
  templateUrl: './pv-default-header.component.html',
  styleUrl: './pv-default-header.component.scss',
})
export class PvDefaultHeaderComponent {
  @Input({ required: false }) title: string = '';
  @Input({ required: false }) voucherData: any;
  @Input() linearSteps: LinearStepInfo[] = [];
  @Input() copy:boolean = true;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['linearSteps']) {
      this.linearSteps = changes['linearSteps'].currentValue;
    }
  }
}
