import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Banner } from './banner.interfaces';

@Component({
  selector: 'sh-banner',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './banner.component.html',
  styleUrl: './banner.component.scss',
})
export class BannerComponent {
  @Input({required: true}) bannerInput: Banner


  cobroDigitalBanner() {
    if (this.bannerInput?.processor === 'RAPIPAGO') {
      return { 'width': '109px', 'height': '30px', 'flex-shrink': '0' };
    }
    if (this.bannerInput?.processor === 'PAGO24') {
      return { 'width': '111px', 'height': '34px', 'flex-shrink': '0' };
    }
    if (this.bannerInput?.processor === 'COBRO_EXPRESS') {
      return { 'width': '128px', 'height': '21px', 'flex-shrink': '0' };
    }
    return {};
  }
}
