import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';

@Component({
  selector: 'ui-card',
  standalone: true,
  imports: [CommonModule, MatCardModule],
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent {
  @Input() voucherCard: boolean = false
  @Input() processorSelectionXs: boolean = false
  @Input() cardStructure: {
    title: boolean
    content: boolean
    actions: boolean
  } = {
      title: true,
      content: true,
      actions: true,
    }

  getClass() {
    return {
      [`voucher-card`]: this.voucherCard,
      [`processor-selection-xs`]: this.processorSelectionXs,
    };
  }
}
