<div class="container"
  [style]="bannerInput.background ? ('background-color:' + bannerInput.background + ';') : 'background-color: #002D74;'">
  <p [style]="bannerInput.colorText ? ('color:' + bannerInput.colorText + ';') : ''">{{ bannerInput.text }}</p>
  @if(bannerInput.img) {
  <img [src]="bannerInput.img" alt="Processor" 
  [ngClass]="
  cobroDigitalBanner() ? 'cobro' : 
  bannerInput.processor === 'YAPE' ? 'yape' : 
  bannerInput.processor === 'PAGO_FACIL' ? 'pagoFacil' :
  (bannerInput.processor && bannerInput.processor!.toString().toLocaleLowerCase()) ? 'efecty' : ''" />
  }
</div>
