import { Component, Input, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InstructionStepper } from './instruction-stepper.interfaces';
import { ResponsiveService } from '../../services/responsive.service';

@Component({
  selector: 'sh-instruction-stepper',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './instruction-stepper.component.html',
  styleUrl: './instruction-stepper.component.scss',
})
export class InstructionStepperComponent {
  esResponsive$ = this.responsiveService.esResponsive$;
  @Input() instruccionStepperInput: InstructionStepper[]
  @Input() column: boolean = false
  @Input() color:string;
  constructor(private responsiveService:ResponsiveService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['instruccionStepperInput']) {
      this.instruccionStepperInput = changes['instruccionStepperInput'].currentValue;
    }
  }

  getStepBackgroundColor(){
    return this.color == 'secondary' ? '#6A00F4' : '#395ae8';
  }

  getStepColor(){
    return this.color == 'secondary' ? '#6A00F4' : '#395ae8';
  }
}
