import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardComponent } from '@angular-monorepo/ui';
import { ProcessorSelectionComponent } from '../processor-selection/processor-selection.component';

@Component({
  selector: 'ps-processor-selection-container',
  standalone: true,
  imports: [CommonModule, CardComponent, ProcessorSelectionComponent],
  templateUrl: './processor-selection-container.component.html',
  styleUrl: './processor-selection-container.component.scss',
})
export class ProcessorSelectionContainerComponent {}
