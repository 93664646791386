<ui-card [processorSelectionXs]="true">
  <div class="title" slot="card_title">
    <h2>Selecciona la entidad
      de tu preferencia</h2>
    <div class="logotipe">
      <img src="./assets/svg/powered-by-monnet-payment-solutions.svg" alt="">
    </div>
  </div>
  <div class="content" slot="card_content">
    <ps-processor-selection></ps-processor-selection>
  </div>
  <div class="actions" slot="card_actions">
    <div class="logotipe">
      <img src="./assets/svg/powered-by-monnet-payment-solutions.svg" alt="">
    </div>
  </div>
</ui-card>
