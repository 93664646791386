import { EnvironmentService } from '@angular-monorepo/environment';
import { Injectable, WritableSignal, computed, signal } from '@angular/core';
import { GetPaymentPayloadRequest, GetPaymentResponseRequest } from './cash-processor.interface';
import { catchError, take, tap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { State } from '../../interfaces';

@Injectable({
  providedIn: 'root'
})
export class CashProcessorService {
  #getPaymentData: WritableSignal<State<GetPaymentResponseRequest>> = signal<State<GetPaymentResponseRequest>>({ data: {} as GetPaymentResponseRequest, loading: false })

  public paymentData = computed(() => this.#getPaymentData().data)
  public paymentLoading = computed(() => this.#getPaymentData().loading)
  public getPaymentError = computed(() => this.#getPaymentData().error)

  constructor(
    private environmentService: EnvironmentService,
    private http: HttpClient,
  ) { }

  getPayment(data: GetPaymentPayloadRequest): void {
    this.#getPaymentData.set({ data: {} as GetPaymentResponseRequest, loading: true })
    this.http.get<GetPaymentResponseRequest>(`${this.environmentService?.env?.apiConfig?.cashProcessorUrl}/${data.merchantId}/${data.operationId}?verification=${data.verification}`)
      .pipe(
        take(1),
        tap((response: GetPaymentResponseRequest) => {
          this.#getPaymentData.set({ data: response, loading: false })
        }),
        catchError((error) => {
          this.#getPaymentData.set({ data: {} as GetPaymentResponseRequest, loading: false, error: error })
          throw error;
        })
      ).subscribe()
  }
}
