<article  *ngIf="operationInfoStatus && operationInfo">
  <div class="checkout">
    <mat-toolbar class="menu">
      <button mat-icon-button *ngIf="false">
        <mat-icon>arrow_back_ios</mat-icon>
      </button>
  
      <div class="menu__logo">
        <p>Estás pagando con</p>
        <img src="./assets/img/yape-logo.png" alt="yape-logo" />
      </div>
  
      <div></div>
    </mat-toolbar>

    <div class="checkout__status">
      @if(operationInfoStatus && operationInfoStatus.success){
      <img src="./assets/svg/success.svg" alt="success">
      <p>Transacción exitosa</p>
      <span style="color: #395AE8;">{{operationInfo.operation.currencySymbol}} {{operationInfo.operation.amount}}</span>
      }@else{
      <img src="./assets/svg/error.svg" alt="success">
      <p>Transacción rechazada</p>
      <span style="color: #EB0046;">{{operationInfo.operation.currencySymbol}} {{operationInfo.operation.amount}}</span>
      }

      <div class="checkout__status--info">
        <h2 class="checkout__status--info__title">Detalle de pago</h2>

        <div>
          <div class="checkout__status__item">
            <p class="checkout__status__item--name">Número de celular</p>
            <p class="checkout__status__item--value">{{operationInfoStatus.phoneNumber}}</p>
          </div>
          <div class="checkout__status__item">
            <p class="checkout__status__item--name">Monto</p>
            <p class="checkout__status__item--value">{{operationInfo.operation.currencySymbol}} {{operationInfo.operation.amount}}</p>
          </div>
          <div class="checkout__status__item">
            <p class="checkout__status__item--name">Número de transacción</p>
            <p class="checkout__status__item--value">{{operationInfoStatus.id}}</p>
          </div>
          <div class="checkout__status__item">
            <p class="checkout__status__item--name">Fecha y hora</p>
            <p class="checkout__status__item--value"> {{ operationInfoStatus.processDate | date: 'dd/MM/yyyy - h:mm a' }} </p>
          </div>
        </div>

        <img src="./assets/svg/monnet.svg" alt="" style="width: 73px;height: 29.638px;flex-shrink: 0;margin-bottom: 0.5rem;margin-left: auto;margin-right: auto;">
      </div>


      <a class="btn-download" >
        <img src="./assets/svg/download-icon.svg" alt="download-icon">
        Descargar constancia
      </a>
    </div>

    

    <div></div>

  </div>

</article>