import { APP_INITIALIZER, ApplicationConfig } from '@angular/core';
import { provideRouter } from '@angular/router';
import { appRoutes } from './app.routes';
import { provideAnimations } from '@angular/platform-browser/animations';
import { EnvironmentService } from '@angular-monorepo/environment';
import { environment } from '../environments/environment';
export const appConfig: ApplicationConfig = {
  providers: [provideRouter(appRoutes), provideAnimations(),
    {
      provide: APP_INITIALIZER,
      useFactory: (configService: EnvironmentService) => () => configService.setConfig(environment),
      deps: [EnvironmentService],
      multi: true
    }
  ],
};
