<div class="container" id="voucherTemplate">
  <div class="header">
    @if(voucherPayin){
        <pv-default-header [title]="'Información de pago'" [voucherData]="voucherData" [linearSteps]="data.LinearStepInfo" [copy]="false"></pv-default-header>
    }@else{
      <dcv-voucher-header [clipboard]="false" [title]="'Información de pago'" [linearSteps]="data.LinearStepInfo" [color]="color" />
    }
  </div>
  <div class="text" *ngIf="layout !== 'qr'">
    <h3>Instrucciones de pago</h3>
  </div>
  <br>

  @if(layout == 'qr' || (data && data.paymentInstructions && data.paymentInstructions.length == 1)){
    @if(layout == 'qr' && processors){
      <ps-processor-static-view [processors]="processors" ></ps-processor-static-view>
    }
    
    @for (paymentInstruction of data.paymentInstructions; track $index) {
      <div class="instruction" >
        <div class="instruction-title">
          <sh-banner [bannerInput]="parseBanner(paymentInstruction.bank, paymentInstruction?.hexColor || '')" />
        </div>
        <div class="instructions instruction-qr" >
          <ng-container  *ngFor="let instruction of paymentInstruction.instruction">
            <div  style="margin-top: 1rem;">
              <span class="number-qr" [style.color]="getStepColor()">{{instruction.step}}.</span>
              <span [innerHTML]="instruction.description" ></span>
            </div>
          </ng-container>
        </div>
      </div>
      }
  }@else{
    <div class="body">
      <div class="layout-three">
        <div class="instruction-list">
        
            @for (paymentInstruction of data.paymentInstructions; track $index) {
              <div class="instruction" [ngClass]="$index % 2 != 0 ? 'border-left' : ''">
                <div class="instruction-title">
                  <sh-banner [bannerInput]="parseBanner(paymentInstruction.bank, paymentInstruction?.hexColor || '')" />
                </div>
                <div class="instructions">
                  
                  <sh-instruction-stepper [column]="true"
                    [instruccionStepperInput]="paymentInstruction.instruction" [color]="color"></sh-instruction-stepper>
                </div>
              </div>
              }
          
        
        </div>
      </div>
    </div>
  }

  
  <div class="footer">
    <div class="logotipe">
      <img src="./assets/svg/powered-by-monnet-payment-solutions.svg" alt="">
    </div>
  </div>
