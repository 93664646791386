import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Processors } from '../processor-selectable/processor-selectable.interfaces';
import { ResponsiveService } from 'libs/shared/src/lib/services/responsive.service';

@Component({
  selector: 'ps-processor-static-view',
  standalone: true,
  imports: [CommonModule],
  providers: [ResponsiveService],
  templateUrl: './processor-static-view.component.html',
  styleUrl: './processor-static-view.component.scss',
})
export class ProcessorStaticViewComponent {
  @Input() processors: Processors[] = []
  esResponsive$ = this.responsiveService.esResponsive$;
  firstHalf: { img: string }[] = [];
  secondHalf: { img: string }[] = [];
  constructor(private responsiveService: ResponsiveService){
 
  }

  async ngOnInit() {
    await this.convertImagesToBase64();

    if (this.processors.length > 5) {
      const midPoint = Math.ceil(this.processors.length / 2) - 1;
      this.firstHalf = this.processors.slice(0, midPoint);
      this.secondHalf = this.processors.slice(midPoint);
    } else {
      this.firstHalf = this.processors;
      this.secondHalf = [];
    }
  }

  private async convertImagesToBase64() {
    const promises = this.processors.map(async processor => {
      processor.img = await this.toBase64(processor.img);
      // processor.img = processor.img;
    });
    await Promise.all(promises);
  }

  private toBase64(url: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onload = function() {
        const reader = new FileReader();
        reader.onloadend = function() {
          resolve(reader.result as string);
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.onerror = function() {
        reject(new Error('Failed to convert image to base64'));
      };
      xhr.open('GET', url);
      xhr.responseType = 'blob';
      xhr.send();
    });
  }
}



