import { Route } from '@angular/router';
import { FormNiubizYapeComponent } from './pages/form-niubiz-yape/form-niubiz-yape.component';
import { ErrorComponent } from './pages/error/error.component';


export const appRoutes: Route[] = [
    {
      path: 'niubiz/wallet/:transactionId',
      component: FormNiubizYapeComponent,
    },
  {
    path: 'error',
    component: ErrorComponent
  },
  {
    path: 'error-status',
    component: ErrorComponent
  },
  {
    path: '**',
    component: ErrorComponent,
  },
  ];