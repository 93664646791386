import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'error-page',
  standalone: true,
  imports: [CommonModule,MatToolbarModule,MatIconModule],
  templateUrl: './error-page.component.html',
  styleUrl: './error-page.component.scss',
})
export class ErrorPageComponent {
  title:string = '¡Ups! Ocurrió un problema';
  subtitle:string = 'Lo sentimos, vuelve a intentarlo más tarde.'
  
  constructor(private activatedRoute: ActivatedRoute) {
  
    this.activatedRoute.url.subscribe((url) => {
      if(url[0].path == 'error-status'){
        this.subtitle = 'Lo sentimos, esta transacción expiró. <br> Intenta un nuevo pago'
      }
    });
   }
}
