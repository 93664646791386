import { Route } from '@angular/router';
import { FormNiubizYapeComponent } from './pages/form-niubiz-yape/form-niubiz-yape.component';
import { ErrorComponent } from './pages/error/error.component';
import { FormCoinnangDebinComponent } from './pages/form-coinnang-debin/form-coinnang-debin.component';
import { VoucherPayphoneWrapperComponent } from './pages/voucher-payphone/voucher-payphone-wrapper.component';


export const appRoutes: Route[] = [
  {
    path: 'niubiz/wallet/:transactionId',
    component: FormNiubizYapeComponent,
  },
  {
    path: 'coinag/bt/:transactionId',
    component: FormCoinnangDebinComponent,
  },
  {
    path: "payphone/:method/:transactionId", component: VoucherPayphoneWrapperComponent
  },
  {
    path: 'error',
    component: ErrorComponent
  },
  {
    path: 'error-status',
    component: ErrorComponent
  },
  {
    path: '**',
    component: ErrorComponent,
  },
];