import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'ps-processor-selection',
  standalone: true,
  imports: [CommonModule, MatButtonModule],
templateUrl: './processor-selection.component.html',
  styleUrl: './processor-selection.component.scss',
})
export class ProcessorSelectionComponent {
  processors: string[] = [
    './assets/processor-images/BBVA_2019 1.svg',
    './assets/processor-images/Interbank_logo 1.svg',
    './assets/processor-images/Logo Yape 2 1.svg',
    './assets/processor-images/Logo_Kasnet 1.svg',
    './assets/processor-images/Logo-bcp-vector 1.svg',
    './assets/processor-images/pago_efec.svg',
    './assets/processor-images/tambo.svg',
  ]
}
