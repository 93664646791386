<mat-dialog-content>
  <ui-spinner [diameter]="diameter" [spinnerButton]="spinnerButton" [color]="color" [mode]="mode" [value]="value">
    @if(showImage) {
    <div slot="image" >
      <img class="img"  [src]="image" alt="" />
    </div>
  }
  </ui-spinner>

</mat-dialog-content>
