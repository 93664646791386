<button mat-button [ngClass]="'color'">
  <div class="body">
    <div class="mobile-check">
      <img src="./assets/svg/mobile-check.svg" alt="">
    </div>
    <div class="button-body">
      <div class="check-image">
        <img src="./assets/svg/is-ready.svg" alt="">
      </div>
      <div class="label">
        <span class="title">{{ label }}</span>
      </div>
    </div>
  </div>
</button>
