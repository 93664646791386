import { EnvironmentService } from '@angular-monorepo/environment';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GetOperationMoviiResponse, MoviiCreateOperationRequest, MoviiCreateOperationResponse, MoviiSessionDetails } from './movii.interface';


@Injectable({
  providedIn: 'root'
})
export class MoviiService {
  constructor(private environmentService: EnvironmentService, private http: HttpClient) { }

  findDocumentByCountry(): Observable<DocumentType[]> {
    return this.http.get<DocumentType[]>(this.environmentService?.env?.apiConfig?.payinCheckoutBackendUrl + 'common/master-parameters/api/find/documentCountry/COL?documentType=N');
  }


  createOperation(body: MoviiCreateOperationRequest): Observable<MoviiCreateOperationResponse> {
    return this.http.post<MoviiCreateOperationResponse>(this.environmentService?.env?.apiConfig?.payinCheckoutBackendUrl + `common/operation/create`, body)
  }

  getPaymentDetails(verification: string): Observable<MoviiSessionDetails> {
    return this.http.get<MoviiSessionDetails>(this.environmentService?.env?.apiConfig?.payinCheckoutBackendUrl + `common/session/find/voucher/movii/${verification}`)
  }

  getOperation(transactionId: string, verification: string): Observable<GetOperationMoviiResponse> {

    return this.http.get<GetOperationMoviiResponse>(this.environmentService?.env?.apiConfig?.payinCheckoutBackendUrl + '/common/operation/operations/' + transactionId + '?verification=' + verification);
  }

}
