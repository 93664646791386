// import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
// import { CommonModule } from '@angular/common';
// import { Processors } from './processor-selectable.interfaces';
// import { MatButtonModule } from '@angular/material/button';

// @Component({
//   selector: 'ps-processor-selectable',
//   standalone: true,
//   imports: [CommonModule, MatButtonModule,],
//   templateUrl: './processor-selectable.component.html',
//   styleUrl: './processor-selectable.component.scss',
// })
// export class ProcessorSelectableComponent implements OnChanges {
//   @Input({ required: true }) processors: Processors[] = []
//   @Input() selectedProcessor: string = ''
//   @Input() voucherPayin: boolean = false;
//   @Output() onSelectProcessor: EventEmitter<Processors> = new EventEmitter<Processors>()

//   ngOnChanges(changes: SimpleChanges): void {
//     if (changes['processors']) {
//       this.processors = changes['processors'].currentValue
//     }
//   }

//   selectProcessor(processor: Processors) {
//     this.selectedProcessor = processor.name
//     this.onSelectProcessor.emit(processor)
//   }

//   parsePrimaryImage(processor: string): string {
//     if(this.voucherPayin){
//       return `https://payin-checkout-config.s3.amazonaws.com/processors/CobroDigital/collectors/${processor}.png`
//     }else{
//       return `https://monnet-cash-config.s3.amazonaws.com/banks/${processor}/primary.svg`
//     }
//   }

//   parseSecondaryImage(processor: string): string {
//     if(this.voucherPayin){
//       return `https://payin-checkout-config.s3.amazonaws.com/processors/CobroDigital/collectors/${processor}.png`
//     }else{
//     return `https://monnet-cash-config.s3.amazonaws.com/banks/${processor}/secondary.svg`
//     }
//   }
// }
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Processors } from './processor-selectable.interfaces';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'ps-processor-selectable',
  standalone: true,
  imports: [CommonModule, MatButtonModule],
  templateUrl: './processor-selectable.component.html',
  styleUrls: ['./processor-selectable.component.scss'],
})
export class ProcessorSelectableComponent implements OnChanges {
  @Input({ required: true }) processors: Processors[] = [];
  @Input() selectedProcessor: string = '';
  @Input() voucherPayin: boolean = false;
  @Output() onSelectProcessor: EventEmitter<Processors> = new EventEmitter<Processors>();

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['processors']) {
      this.processors = changes['processors'].currentValue;
    }
  }

  selectProcessor(processor: Processors) {
    this.selectedProcessor = processor.name;
    this.onSelectProcessor.emit(processor);
  }

  parsePrimaryImage(processor: string): string {
    return this.voucherPayin
      ? `https://payin-checkout-config.s3.amazonaws.com/processors/CobroDigital/collectors/${processor}/INACTIVE.png`
      : `https://monnet-cash-config.s3.amazonaws.com/banks/${processor}/primary.svg`;
  }

  parseSecondaryImage(processor: string): string {
    let status = this.voucherPayin && processor == 'PAGO_FACIL' ? 'INACTIVE' : 'ACTIVE';
    return this.voucherPayin
      ? `https://payin-checkout-config.s3.amazonaws.com/processors/CobroDigital/collectors/${processor}/${status}.png`
      : `https://monnet-cash-config.s3.amazonaws.com/banks/${processor}/secondary.svg`;
  }

  cobroDigitalBanner(processor?: string) {
    if(!processor){
      return {};
    }

    if (processor === 'RAPIPAGO') {
      return { 'width': '101px', 'height': '23px', 'flex-shrink': '0' };
    }
    if (processor === 'PAGO24') {
      return { 'width': '95px', 'height': '29px', 'flex-shrink': '0' };
    }
    if (processor === 'COBRO_EXPRESS') {
      return { 'width': '100px', 'height': '16px', 'flex-shrink': '0' };
    }
    return {};
  }
}
