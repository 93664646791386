<div class="coinnang-modal-error">
    <img class="coinnang-modal-error__icon" src="./assets/coinnang-debin/logos/Error.png" alt="error" />

    <h3 class="coinnang-modal-error__title">¡Ups! Ocurrió un problema</h3>

    <p class="coinnang-modal-error__detail">Lo sentimos, esta transacción expiró.
        Intenta un nuevo pago.</p>

    <!-- <img class="coinnang-modal-error__logo" src="./assets/coinnang-debin/logos/Logo.png" alt="Monnet" /> -->

    <button class="coinnang-modal-error__action" (click)="onCancel()">Entendido</button>
</div>