export enum ProcessorCode {
  BAMZ = 'BAMZ',
  BBVA = 'BBVA',
  BCP = 'BCP',
  BGUAY = 'BGUAY',
  BNBF = 'BNBF',
  'BPCH-ECU' = 'BPCH-ECU',
  CAQP = 'CAQP',
  DSHP = 'DSHP',
  IBK = 'IBK',
  KSNT = 'KSNT',
  NBZ = 'NBZ',
  RD = 'RD',
  RPBK = 'RPBK',
  SBK = 'SBK',
  TAMBO = 'TAMBO',
  WU = 'WU',
  YAPE = 'YAPE',
}
