import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EnvironmentService } from '@angular-monorepo/environment';

@Injectable({
    providedIn: 'root'
})
export class VoucherPayphoneService {
    constructor(
        private environmentService: EnvironmentService,
        private http: HttpClient) { }

    getOperation(idTransaction: string, verification: string) {
        const headers = new HttpHeaders({
            'verification': verification
        });

        return this.http.post<any>(this.environmentService?.env?.apiConfig?.payinCheckoutBackendUrl + "common/operation/find/" + idTransaction, null, { headers })
    }



}