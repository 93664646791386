<div class="container">
    <div class="title">
      @if(title) {
      <h2>{{title}}</h2>
      }
    </div>
    <div class="ticket-container" *ngIf="voucherData?.voucherData?.ticketNumber">
      <p>Ticket: {{voucherData.voucherData.ticketNumber}}</p>
    </div>
    @if (linearSteps.length > 0) {
    <div class="steps">
      <sh-pv-default-linear-step-info [linearSteps]="linearSteps" [layout]="'code'" [copy]="copy" [voucherData]="voucherData" ></sh-pv-default-linear-step-info>
    </div>
    }
  </div>