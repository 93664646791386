import { EnvironmentService } from '@angular-monorepo/environment';
import { Injectable } from '@angular/core';
import {  Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PaymentDetails, PaymentDetailsStatus, ResponseConfirmOperation } from '../daviplata';

@Injectable({
  providedIn: 'root'
})
export class FormPayinService {


  constructor(
    private environmentService: EnvironmentService,
    private http: HttpClient,
  ) { }

  findDocumentByCountry(): Observable<DocumentType[]> {
    return this.http.get<DocumentType[]>(this.environmentService?.env?.apiConfig?.payinCheckoutBackendUrl + 'common/master-parameters/api/find/documentCountry/COL?documentType=N');
  }


  confirmOperation(idTransaction:string, body:any,verification:string): Observable<PaymentDetailsStatus>{
    const headers = new HttpHeaders({
      'verification':  verification
    });
    return this.http.post<PaymentDetailsStatus>(this.environmentService?.env?.apiConfig?.payinCheckoutBackendUrl +`processor/niubiz/wallet/operation/confirm/${idTransaction}`,body, {headers})
  }

  expirateOperation(idTransaction:string,verification:string): Observable<ResponseConfirmOperation>{
    const headers = new HttpHeaders({
      'verification':  verification
    });
    return this.http.post<ResponseConfirmOperation>(this.environmentService?.env?.apiConfig?.payinCheckoutBackendUrl +`processor/daviplata/operation/expire/${idTransaction}`,null, {headers})
  }

  getPaymentDetails(idTransaction:string,verification:string) : Observable<PaymentDetails>{
    const headers = new HttpHeaders({
      'verification':  verification
    });
    
    return this.http.post<PaymentDetails>(this.environmentService?.env?.apiConfig?.payinCheckoutBackendUrl + `common/operation/find/${idTransaction}`, {}, { headers })
  }
}
