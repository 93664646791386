import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";
import { map, shareReplay } from "rxjs/operators";

@Injectable({
providedIn: "root"
})
export class TimerService  {
	private timerInterval: any;
	private timerString = new BehaviorSubject<string>('');
	private classString = new BehaviorSubject<string>('');
	private titleTimer = new BehaviorSubject<string>('');

	timerString$ = this.timerString.asObservable();
	classString$ = this.classString.asObservable();
	titleTimer$ = this.titleTimer.asObservable();

	startTimer(expirationDate: Date) {
	  this.updateTimer(expirationDate); 
	  this.timerInterval = setInterval(() => {
		this.updateTimer(expirationDate);
	  }, 1000);
	}
  
	updateTimer(expirationDate: Date) {

	const expirationDates = new Date(expirationDate);
	
	const localDate = new Date(); 
	const currentDate = new Date(localDate.getTime() + localDate.getTimezoneOffset() * 60000);

	  const timeDifference = expirationDates.getTime() - currentDate.getTime();
  
	  if (timeDifference <= 0) {
		if (this.timerInterval) {
		  clearInterval(this.timerInterval);
		}
		this.titleTimer.next('Estado');
		this.timerString.next('Pago expirado');
		this.classString.next('red');
		return;
	  }
  
	  const hours = Math.floor(timeDifference / (1000 * 60 * 60));
	  const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
	  const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);
  
	  this.titleTimer.next('Vence en')
	  this.timerString.next(`<b>${this.padZero(hours)}:${this.padZero(minutes)}:${this.padZero(seconds)}</b> horas`);
	}
  
	private padZero(num: number): string {
	  return num < 10 ? '0' + num : num.toString();
	}
  }
