<main>
    <article id="coinnang-debin-comprobante" class="checkout">
        <div class="checkout__header">
            <h3 class="checkout__header__title">Información de pago</h3>
        </div>

        <div class="checkout__detail" [ngStyle]="{ 'height': debin && debin.success ? 'auto' : '108px' }">
            <ng-container *ngIf="debin && debin.success">
                <div class="checkout__detail__success">
                    <img class="checkout__detail__success__logo" src="./assets/coinnang-debin/logos/Success.png"
                        alt="Success" />
                    <p class="checkout__detail__success__title">DEBIN Generado</p>
                    <p class="checkout__detail__success__detail">
                        Un DEBIN ha sido generado como “{{ operation.processor.name }}” a cuenta y
                        orden de {{ operation.merchant.name }}.
                    </p>
                </div>
            </ng-container>

            <div class="checkout__detail__operation">
                <div class="checkout__detail__operation__amount">
                    <p>Monto a pagar</p>
                    <strong *ngIf="operation">{{ operation.operation.amount }} {{
                        operation.operation.currencyCode}}</strong>
                </div>

                <div class="checkout__detail__operation__divider">

                </div>

                <div class="checkout__detail__operation__timer">
                    <p>Vence en</p>
                    <p><strong>{{ countdownTime }}</strong> horas</p>
                </div>
            </div>
        </div>

        <form class="checkout__form" [formGroup]="checkoutForm">
            <ng-container *ngIf="(!debin || !debin.success) && !capturando">
                <div class="checkout__form__alias checkout__form__input">
                    <label>Ingresá el alias de tu cuenta</label>
                    <input formControlName="alias" type="text" />
                    <span class="checkout__form__input__action"
                        [ngClass]="{ 'checkout__form__input__action--disabled': !checkoutForm.value.alias }"
                        (click)="onConsultarAlias()">Enviar</span>
                </div>

                <div class="checkout__form__account">
                    <div class="checkout__form__account__data checkout__form__input">
                        <label>Datos de la cuenta</label>
                        <input placeholder="NOMBRE" formControlName="nombre" type="text" />
                    </div>

                    <div class="checkout__form__account__number">
                        <div class="checkout__form__input checkout__form__input--cbu">
                            <label></label>
                            <input placeholder="Número CBU" formControlName="CBU" type="text" />
                        </div>

                        <div class="checkout__form__input checkout__form__input--cuit">
                            <label></label>
                            <input placeholder="Número CUIT" formControlName="CUIT" type="text" />
                        </div>
                    </div>
                </div>

                <button class="checkout__form__action" [disabled]="!hasData" (click)="onPay()">Solicitá el pago</button>
            </ng-container>

            <p class="checkout__form__title">Podés pagar desde cualquiera de estas entidades</p>

            <div class="checkout__form__cards">
                <img src="./assets/coinnang-debin/logos/Debin.png" alt="Debin" />
                <img src="./assets/coinnang-debin/logos/Santander.png" alt="Santander" />
                <img src="./assets/coinnang-debin/logos/BBVA.png" alt="BBVA" />
                <img src="./assets/coinnang-debin/logos/Galicia.png" alt="Galicia" />
                <img src="./assets/coinnang-debin/logos/Unknown.png" alt="Unknown" />
                <img src="./assets/coinnang-debin/logos/ICBC.png" alt="ICBC" />
                <img src="./assets/coinnang-debin/logos/Macro.png" alt="Macro" />
                <img src="./assets/coinnang-debin/logos/HCBC.png" alt="HCBC" />
            </div>
        </form>

        <div class="checkout__footer">
            <p class="checkout__footer__title">Seguí estos pasos</p>
        </div>

        <div class="checkout__disclaimer">
            <div class="checkout__disclaimer__list">
                <div class="checkout__disclaimer__list__item">
                    <div class="checkout__disclaimer__list__item__order">
                        <p>
                            1
                        </p>
                    </div>
                    <p class="checkout__disclaimer__list__item__detail">
                        Ingresá a la App o banca en línea del banco de tu preferencia.
                    </p>
                </div>

                <div class="checkout__disclaimer__list__item">
                    <div class="checkout__disclaimer__list__item__order">
                        <p>
                            2
                        </p>
                    </div>
                    <p class="checkout__disclaimer__list__item__detail">
                        Buscá la sección DEBIN y aprobá la solicitud de pago enviada.
                    </p>
                </div>

                <div class="checkout__disclaimer__list__item">
                    <div class="checkout__disclaimer__list__item__order">
                        <p>
                            3
                        </p>
                    </div>
                    <p class="checkout__disclaimer__list__item__detail">
                        Luego de aprobar la solicitud, recibirás la confirmación de tu compra y ¡listo!
                        <strong>la
                            operación se
                            procesará automáticamente</strong>.
                    </p>
                </div>
            </div>

            <ng-container *ngIf="debin && debin.success && !capturando">
                <button class="checkout__disclaimer__action" (click)="onPrint()">
                    <p>
                        <img src="./assets/coinnang-debin/iconos/Descargar.svg" alt="Descargar comprobante" />
                        Descargar comprobante
                    </p>
                </button>
            </ng-container>

            <div class="checkout__disclaimer__logo">
                <img src="./assets/coinnang-debin/logos/Monnet.png" alt="" />
            </div>
        </div>
    </article>

</main>