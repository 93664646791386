<button mat-button [disabled]="mediaButtonInput.isLoading" [style]="'cursor:' + cursor + '!important'"
  [ngClass]="mediaButtonInput.color === 'primary' ? 'primary' : mediaButtonInput.color === 'warn' ? 'warn' : 'accent'"
  [style.background-color]="getButtonColor()"
  (click)="mediaButtonInput.onClick ? mediaButtonInput.onClick() : onClick()">
  <div class="button-body">
    @if (mediaButtonInput.isLoading) {
    <ui-spinner [diameter]="'25'"></ui-spinner>
    } @else { <div class="img">
      @for (images of mediaButtonInput.imagesUrl; track $index) {
      <img [src]="image(images)" [alt]="mediaButtonInput.label" />
      }
    </div>
    <div class="label">
      <span class="title">{{ mediaButtonInput.label }}</span>
    </div>}
  </div>
</button>
