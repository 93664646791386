<div class="container">
  <div class="title"  [ngStyle]="{'background-color': color == 'primary' || !color ? '#395ae8' :  '#6A00F4'}">
    @if(title) {
    <h2>{{title}}</h2>
    }
  </div>
  @if (linearSteps.length > 0) {
  <div class="steps">
    <sh-linear-step-info [color]="color"  [linearSteps]="linearSteps" [clipboard]="clipboard"></sh-linear-step-info>
  </div>
  }
</div>
