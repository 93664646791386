import { Component, effect, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { HttpClientModule } from '@angular/common/http';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CustomSpinnerComponent } from '@angular-monorepo/ui';
import { FormPayinService, PaymentDetails, PaymentDetailsStatus } from '@angular-monorepo/api-services';
import { ActivatedRoute, Router } from '@angular/router';
import { CaptureScreenService } from '@angular-monorepo/screenshot';
import { PdfJsService } from '@angular-monorepo/pdf';
import jsPDF from 'jspdf';
import { DownloadVoucherFormYapeComponent } from '../download-voucher-form-yape/download-voucher-form-yape.component';

export enum CheckoutEstado {
  INGRESO_DATOS = 1,
  FINALIZADO = 2,
}

@Component({
  selector: 'checkout-payin-form-container',
  standalone: true,
  imports: [CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatButtonModule,
    MatInputModule,
    MatCheckboxModule,
    MatIconModule,
    MatToolbarModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    CustomSpinnerComponent,
    DownloadVoucherFormYapeComponent
  ],
  templateUrl: './checkout-payin-form-container.component.html',
  styleUrl: './checkout-payin-form-container.component.scss',
})
export class CheckoutPayinFormContainerComponent {
  screenRecordService = inject(CaptureScreenService)
  jsPdfService = inject(PdfJsService)

  estado: CheckoutEstado = CheckoutEstado.INGRESO_DATOS;
  estadoModel = CheckoutEstado;
  operationInfo: PaymentDetails;
  operationInfoStatus: PaymentDetailsStatus;
  transactionId: string;
  verification: string;
  form: FormGroup;
  success: boolean = true;
  initLoading: boolean = true;
  loading: boolean = false;
  download: boolean = false;
  intervalRef: any;

  constructor(private formPayinService: FormPayinService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder) { }

  ngOnInit(): void {
    this.initForm();
    this.route.params.subscribe(params => {
      this.transactionId = params['transactionId'];
    }
    );

    this.route.queryParams.subscribe(params => {
      this.verification = params['verification'];
    });

    this.getPaymentDetails();


    this.refreshVoucher()

  }

  refreshVoucher(): void {

    if (this.intervalRef) { clearInterval(this.intervalRef); }


    this.intervalRef = setInterval(() => {
      this.getPaymentDetails();
    }, 240000);


  }

  initForm(): void {
    this.form = this.fb.group({
      phoneNumber: ['', [Validators.required, Validators.maxLength(9), Validators.minLength(9)]],
      otp: ['', [Validators.required, Validators.maxLength(6), Validators.minLength(6)]],
    });
  }
  getPaymentDetails(): void {

    this.formPayinService.getPaymentDetails(this.transactionId, this.verification)
      .subscribe(
        {
          next: (data) => {
            this.operationInfo = data;
            this.initLoading = false;
            if (this.operationInfo.processor.name != 'Niubiz_wlt' || this.operationInfo.operation.status != '2') {
              this.router.navigate(['/error-status']);
            }

            this.form.patchValue({
              otp: this.operationInfo.customer.phone
            })

            if (this.operationInfo.attributes) {
              const disabledPhone = this.operationInfo.attributes.find((a) => a.code === "phone_editable");
              if (disabledPhone && disabledPhone?.value === "1") {
                this.form.get("otp")?.disable();
              }
            }

          },
          error: (error) => {
            console.error('Error en la solicitud:', error);
            this.initLoading = false;
          }
        }
      );
  }

  phoneNumberRequiredValid(): boolean | undefined {
    return this.form.get('phoneNumber')?.hasError('required') && this.form.get('phoneNumber')?.touched
  }
  phoneNumberMinLengthValid(): boolean | undefined {
    return this.form.get('phoneNumber')?.hasError('minlength') && this.form.get('phoneNumber')?.touched
  }

  phoneNumberMaxLengthValid(): boolean | undefined {
    return this.form.get('phoneNumber')?.hasError('maxlength') && this.form.get('phoneNumber')?.touched
  }

  otpRequiredValid(): boolean | undefined {
    return this.form.get('otp')?.hasError('required') && this.form.get('otp')?.touched
  }
  otpMinLengthValid(): boolean | undefined {
    return this.form.get('otp')?.hasError('minlength') && this.form.get('otp')?.touched
  }

  otpMaxLengthValid(): boolean | undefined {
    return this.form.get('otp')?.hasError('maxlength') && this.form.get('otp')?.touched
  }

  formatValue(key: string) {
    let formatDocument = this.form.controls[key].value.replace(/\D/g, '');
    this.form.controls[key].setValue(formatDocument);
  }

  confirmOperation() {
    this.loading = true;
    this.formPayinService.confirmOperation(this.transactionId, this.form.value, this.operationInfo.verificationResult)
      .subscribe({
        next: (data: any) => {
          this.success = data.success;
          this.operationInfoStatus = data;
          this.estado = CheckoutEstado.FINALIZADO;
          this.loading = false;
        },
        error: (error) => {
          this.loading = false;
          this.success = false;
          this.estado = CheckoutEstado.FINALIZADO;
        }
      });
  }

  downloadVoucher() {
    this.download = true;
    setTimeout(() => {

      this.screenRecordService.captureWithPromise('comprobante-pago').then((imageData: string) => {
        this.download = false;

        const link = document.createElement('a');
        link.href = imageData;
        link.download = 'yape.png';
        link.click();
      });
    }, 100);
  }

  redirectToUrl() {
    if (this.success) {
      window.location.href = this.operationInfo.operation.okUrl;
    } else {
      window.location.href = this.operationInfo.operation.errorUrl;
    }
  }

  ngOnDestroy(): void {
    if (this.intervalRef) { clearInterval(this.intervalRef); }
  }

  // downloadVoucher(){
  //     this.download = true;
  //     setTimeout(() => {

  //       this.screenRecordService.captureWithPromise('comprobante-pago').then((imageData: string) => {
  //         this.download = false;

  //         const img = new Image();
  //         img.src = imageData;

  //         img.onload = () => {
  //           const imgWidth = img.width;
  //           const imgHeight = img.height;

  //           const doc = new jsPDF({
  //             orientation: imgWidth > imgHeight ? 'landscape' : 'portrait',
  //             unit: 'px',
  //             format: [imgWidth, imgHeight]
  //           });

  //           doc.addImage(
  //             imageData,
  //             'JPEG',
  //             0,
  //             0,
  //             imgWidth,
  //             imgHeight
  //           );

  //           doc.save('yape.pdf');
  //         };
  //       });
  //     }, 100);

  // }
}
