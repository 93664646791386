import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EnvironmentService } from '@angular-monorepo/environment';

@Injectable({
    providedIn: 'root'
})
export class CheckoutFormCoinnangDebinService {
    constructor(
        private environmentService: EnvironmentService,
        private http: HttpClient) { }

    getOperation(idTransaction: string, verification: string) {
        const headers = new HttpHeaders({
            'verification': verification
        });

        return this.http.post<any>(this.environmentService?.env?.apiConfig?.payinCheckoutBackendUrl + "common/operation/find/" + idTransaction, null, { headers })
    }

    consultAlias(idTransaction: string, alias: string, verification: string) {
        const headers = new HttpHeaders({
            'verification': verification
        });

        return this.http.post<any>(this.environmentService?.env?.apiConfig?.payinCheckoutBackendUrl + "processor/coinag/operation/consult-alias/" + idTransaction, { alias: alias }, { headers })
    }

    createDebin(idTransaction: string, verification: string) {
        const headers = new HttpHeaders({
            'verification': verification
        });

        return this.http.post<any>(this.environmentService?.env?.apiConfig?.payinCheckoutBackendUrl + "processor/coinag/operation/create-debin/" + idTransaction, {}, { headers })

    }
}