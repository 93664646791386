import { Component, Input, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LinearStepInfo, PvQrStepInfoComponent } from '@angular-monorepo/shared';

@Component({
  selector: 'pv-qr-header',
  standalone: true,
  imports: [CommonModule,PvQrStepInfoComponent],
  templateUrl: './pv-qr-header.component.html',
  styleUrl: './pv-qr-header.component.scss',
})
export class PvQrHeaderComponent {
  @Input({ required: false }) title: string = '';
  @Input({ required: false }) ticket: string = '';
  @Input({ required: false }) voucherData: any = null;
  @Input() linearSteps: LinearStepInfo[] = [];
  
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['voucherData']) {
      this.voucherData = changes['voucherData'].currentValue;
    }
  }
}
