import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MediaButton } from '../../interfaces';
import { SpinnerComponent } from '../spinner/spinner.component';

@Component({
  selector: 'ui-media-button',
  standalone: true,
  imports: [CommonModule, MatButtonModule, SpinnerComponent],
  templateUrl: './media-button.component.html',
  styleUrl: './media-button.component.scss',
})
export class MediaButtonComponent {
  @Input({ required: true }) mediaButtonInput: MediaButton
  @Input() cursor: string = 'pointer'

  @Output() onClickEvent: EventEmitter<void> = new EventEmitter<void>()

  onClick(): void {
    this.onClickEvent.emit()
  }

  image(image: string): string {
    return image
  }

  getButtonColor(): string {
    return this.mediaButtonInput.color || ''
  }
}
