import { Environment } from "@angular-monorepo/environment";

export const certification: Environment = {
  appName: 'Direct Connections CERT',
  production: false,
  apiConfig: {
    cashProcessorUrl: 'https://cert.experience.cash.monnet.io/api/v1/ms-experience-monnet-cash/payment/voucher',
    payinCheckoutBackendUrl:'https://cert.payin.experience.monnet.io/api/v1/payin-checkout-experience/',
    voucherPayinExperienceUrl:'TODO: Add URL here'
  }
};

