<div class="container">
  <ui-page-template [bgBlue]="true" [showFooter]="true">
    <div slot="content">
      <div class="layout">
        <div class="container">
          <div class="img-loading">
            <img src="../../../../../assets/svg/bad-error.svg" alt="">
          </div>
          <div class="info">
            <h2 class="message">Pagina no encontrada</h2>
            <p class="sub-message">
              La pagina que estas buscando no existe o no esta disponible
            </p>
          </div>
        </div>
      </div>
    </div>
  </ui-page-template>
</div>
