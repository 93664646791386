@if(initLoading){
<div class="loading">
  <ui-custom-spinner [diameter]="'80'" [mode]="'indeterminate'" [image]="'./assets/svg/monnet.svg'"></ui-custom-spinner>
</div>
}@else{

<article>
  <div class="checkout">
    <mat-toolbar class="menu">
      <button mat-icon-button *ngIf="!estadoModel.INGRESO_DATOS">
        <mat-icon>arrow_back_ios</mat-icon>
      </button>

      <div class="menu__logo">
        <p>Estás pagando con</p>
        <img src="./assets/img/yape-logo.png" alt="yape-logo" />
      </div>

      <div></div>
    </mat-toolbar>

    @if(estado == estadoModel.INGRESO_DATOS){

    <div class="checkout__info">
      <div class="">
        <p class="checkout__info--subtitle">Monto a pagar</p>
        <p class="checkout__info--mount">{{operationInfo.operation.currencySymbol}} {{operationInfo.operation.amount}}
        </p>
      </div>
    </div>


    <div class="checkout__form" [formGroup]="form">
      <h3 class="title-form">Ingresa tus datos</h3>

      <div class="checkout__form--input">
        <span class="checkout__form--input__title">Número de celular asociado a Yape</span>
        <input (input)="formatValue('phoneNumber')"
          [ngClass]="{'error': phoneNumberRequiredValid() || phoneNumberMaxLengthValid()|| phoneNumberMinLengthValid() }"
          formControlName="phoneNumber" placeholder="Ingresar número...">
        <mat-error class="form-error-message" *ngIf="phoneNumberRequiredValid()">
          El campo es requerido
        </mat-error>
        <mat-error class="form-error-message" *ngIf="phoneNumberMaxLengthValid()">
          El campo tiene un máximo de 9 dígitos
        </mat-error>
        <mat-error class="form-error-message" *ngIf="phoneNumberMinLengthValid()">
          El campo tiene un mínimo de 9 dígitos
        </mat-error>
      </div>

      <div class="checkout__form--input">
        <span class="checkout__form--input__title">Código de aprobación</span>
        <input (input)="formatValue('otp')"
          [ngClass]="{'error': otpRequiredValid() || otpMaxLengthValid() || otpMinLengthValid() }" class=""
          formControlName="otp" placeholder="Ingresar código...">
        <mat-error class="form-error-message" *ngIf="otpRequiredValid()">
          El campo es requerido
        </mat-error>
        <mat-error class="form-error-message" *ngIf="otpMaxLengthValid()">
          El campo tiene un máximo de 6 dígitos
        </mat-error>
        <mat-error class="form-error-message" *ngIf="otpMinLengthValid()">
          El campo tiene un mínimo de 6 digitos
        </mat-error>
      </div>

      <div class="checkout__tutorial">
        <p class="checkout__tutorial--title">¿Cómo consigo el código de aprobación?</p>
        <ul>
          <li>Inicia sesión en tu <strong>App de Yape</strong> y dirígete a tu <strong>Menú Principal.</strong></li>
          <li>Da click a <strong>“Código de Aprobación”</strong> y aparecerá un código de 6 dígitos</li>
          <li> <strong>Digíta el código en el campo “Código de aprobación”</strong> de este formulario. </li>
        </ul>
      </div>

      <div class="checkout__activar">
        <div class="checkout__activar__icono">
          <img src="./assets/icon/icono-info.svg" alt="Info" />
        </div>
        <p class="checkout__activar__detalle">
          ¿No puedes completar tu pago? No te olvides de activar las <strong>“Compras por internet”.</strong>
        </p>
      </div>

      <img src="./assets/svg/monnet.svg" alt="" style="margin-top: 3rem;height: 29.638px;flex-shrink: 0;">
    </div>


    <button class="btn" mat-button [disabled]="form.invalid" (click)="confirmOperation()">
      @if(loading){
      <div class="spinner">
        <ui-custom-spinner [diameter]="'30'" [mode]="'indeterminate'" [showImage]="false"></ui-custom-spinner>
      </div>
      }@else{
      Yapear
      }
    </button>

    }

    @if(estado == estadoModel.FINALIZADO){
    <div class="checkout__status">
      @if(success){
      <img src="./assets/svg/success.svg" alt="success">
      <p>Transacción exitosa</p>
      <span style="color: #395AE8;">{{operationInfo.operation.currencySymbol}} {{operationInfo.operation.amount}}</span>
      }@else{
      <img src="./assets/svg/error.svg" alt="success">
      <p>Transacción rechazada</p>
      <span style="color: #EB0046;">{{operationInfo.operation.currencySymbol}} {{operationInfo.operation.amount}}</span>
      }

      <div class="checkout__status--info">
        <h2 class="checkout__status--info__title">Detalle de pago</h2>

        <div>
          <div class="checkout__status__item">
            <p class="checkout__status__item--name">Número de celular</p>
            <p class="checkout__status__item--value">{{operationInfoStatus.phoneNumber}}</p>
          </div>
          <div class="checkout__status__item">
            <p class="checkout__status__item--name">Monto</p>
            <p class="checkout__status__item--value">{{operationInfo.operation.currencySymbol}}
              {{operationInfo.operation.amount}}</p>
          </div>
          <div class="checkout__status__item">
            <p class="checkout__status__item--name">Número de transacción</p>
            <p class="checkout__status__item--value">{{operationInfoStatus.id}}</p>
          </div>
          <div class="checkout__status__item">
            <p class="checkout__status__item--name">Fecha y hora</p>
            <p class="checkout__status__item--value"> {{ operationInfoStatus.processDate | date: 'dd/MM/yyyy - h:mm a'
              }} </p>
          </div>
        </div>


        <img src="./assets/svg/monnet.svg" alt=""
          style="width: 73px;height: 29.638px;flex-shrink: 0;margin-bottom: 0.5rem;margin-left: auto;margin-right: auto;">
      </div>

      <a class="btn-download" (click)="downloadVoucher()">
        <img src="./assets/svg/download-icon.svg" alt="download-icon">
        Descargar constancia
      </a>
    </div>

    <button class="btn" mat-button (click)="redirectToUrl()">
      Retornar al comercio
    </button>
    }

  </div>

</article>
}


<download-voucher-form-yape [ngClass]="{'hidden': !download}" [operationInfo]="operationInfo"
  [operationInfoStatus]="operationInfoStatus" id="comprobante-pago"></download-voucher-form-yape>