import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconButtonComponent } from '@angular-monorepo/ui';
import { LinearStepInfo, LinearStepInfoComponent } from '@angular-monorepo/shared';

@Component({
  selector: 'dcv-voucher-header',
  standalone: true,
  imports: [CommonModule, IconButtonComponent, LinearStepInfoComponent],
  templateUrl: './voucher-header.component.html',
  styleUrl: './voucher-header.component.scss',
})
export class VoucherHeaderComponent implements OnChanges {
  @Input({ required: false }) title: string = '';
  @Input() linearSteps: LinearStepInfo[] = [];
  @Input() clipboard: boolean = true
  @Input() color: string = 'primary';
  @Input() secondaryColor:string;
  @Input() dividerColor:string = '#C8D5DF';
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['linearSteps']) {
      this.linearSteps = changes['linearSteps'].currentValue;
    }
  }
}
