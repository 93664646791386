import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckoutFormCoinnangDebinService } from './checkout-form-coinnang-debin.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { HttpClientModule } from '@angular/common/http';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute } from '@angular/router';
import { catchError, EMPTY, finalize, Subscription } from 'rxjs';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { GetOperationResponse, ICreateDebinResponse, IGetOperationResponse } from './get-operation.response';
import { MatDialog } from '@angular/material/dialog';
import moment from 'moment';
import { CheckoutCoinnangModalErrorComponent } from '../checkout-coinnang-modal-error/checkout-coinnang-modal-error.component';
import { CaptureScreenService } from '@angular-monorepo/screenshot';
import { PdfJsService } from '@angular-monorepo/pdf';
import { CheckoutCoinnangModalLoadingComponent } from '../checkout-coinnang-modal-loading/checkout-coinnang-modal-loading.component';

@Component({
  selector: 'angular-monorepo-checkout-form-coinnang-debin',
  standalone: true,
  imports: [CommonModule, MatFormFieldModule,
    HttpClientModule, MatIconModule, ReactiveFormsModule],
  templateUrl: './checkout-form-coinnang-debin.component.html',
  styleUrl: './checkout-form-coinnang-debin.component.scss',
})
export class CheckoutFormCoinnangDebinComponent implements OnInit, OnDestroy {
  private readonly subscriptions = new Subscription();
  isProcessing = false;
  verification: string | null;
  transactionId: string | null
  checkoutForm: FormGroup = this.fb.group({
    alias: [{ value: undefined, disabled: false }, Validators.required],
    nombre: [{ value: undefined, disabled: true }, Validators.required],
    CBU: [{ value: undefined, disabled: true }, Validators.required],
    CUIT: [{ value: undefined, disabled: true }, Validators.required]
  });
  title: string = "Solicitud de pago"
  operation: IGetOperationResponse;
  debin: ICreateDebinResponse | undefined = undefined;
  hasData: boolean = false;
  errorAlias: boolean = false
  expired: boolean = false;

  screenRecordService = inject(CaptureScreenService)
  jsPdfService = inject(PdfJsService)
  capturando: boolean = false;
  countdownTime: string = '00:00:00';

  constructor(private _matDialog: MatDialog, private readonly checkoutFormCoinnangDebinService: CheckoutFormCoinnangDebinService, private readonly activatedRoute: ActivatedRoute, private readonly fb: FormBuilder) { }

  ngOnInit(): void {
    this.subscriptions.add(
      this.activatedRoute.params.subscribe(params => {
        this.transactionId = params['transactionId'];

        this.subscriptions.add(
          this.activatedRoute.queryParams.subscribe(params => {
            this.verification = params['verification'];

            this.subscriptions.add(
              this.checkoutFormCoinnangDebinService.getOperation(this.transactionId!, this.verification!).subscribe(response => {
                this.operation = new GetOperationResponse(response)
                this.startCountdown(this.operation.operation.expirationDate);

              })
            )
          })
        )

      })
    )
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onConsultarAlias() {
    if (!this.checkoutForm.value.alias) return;

    const modal = this._matDialog.open(CheckoutCoinnangModalLoadingComponent, {
      disableClose: true,
      width: "320px",
      height: "208px"
    })

    this.checkoutFormCoinnangDebinService.consultAlias(this.transactionId!, this.checkoutForm.value.alias, this.operation.verificationResult!)
      .pipe(
        catchError(error => {
          this.errorAlias = true;
          return EMPTY
        }),
        finalize(() => {
          modal.close();
        })
      )
      .subscribe(response => {
        if (!response) return
        this.errorAlias = false;

        this.checkoutForm.patchValue({
          nombre: response.name,
          CBU: response.cbu,
          CUIT: response.cuit
        })

        this.hasData = true;
      });
  }

  

  onPay() {
    if (this.isProcessing) {
      return;
    }
  
    this.isProcessing = true;
  
    this.checkoutFormCoinnangDebinService.createDebin(this.transactionId!, this.operation.verificationResult)
      .subscribe({
        next: (response) => {
          this.debin = response;
        },
        error: (error) => {
          console.error('Error al generar DEBIN', error);
        },
        complete: () => {
          this.isProcessing = false;
        }
      });
  }
  

  private startCountdown(targetDate: string): void {
    const target = moment(targetDate);

    const intervalId = setInterval(() => {
      const now = moment();
      const diff = target.diff(now);

      if (diff <= 0) {
        clearInterval(intervalId);
        this.countdownTime = '00:00:00';
        this.expired = true;

        const modal = this._matDialog.open(CheckoutCoinnangModalErrorComponent, {
          disableClose: true,
          width: "503px",
          height: "428px"
        });

        modal.afterClosed().subscribe(() => {
          window.open(this.operation.operation.errorUrl, '_self')
        })
      } else {
        const duration = moment.duration(diff);
        const totalHours = Math.floor(duration.asHours()).toString().padStart(2, '0');
        const minutes = duration.minutes().toString().padStart(2, '0');
        const seconds = duration.seconds().toString().padStart(2, '0');

        this.countdownTime = `${totalHours}:${minutes}:${seconds}`;
      }
    }, 1000);
  }

  onPrint() {
    this.capturando = true;
    this.title = "Información de pago";

    setTimeout(() => {
      this.screenRecordService.captureScreen("coinnang-debin-comprobante")

      this.screenRecordService.generatedImage$.subscribe(base64 => {
        const link = document.createElement("a");
        link.href = base64;
        link.download = "coinnang-debin";
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        this.capturando = false;
        this.title = "Solicitud de pago"

      })
    }, 0);

  }
}
