export interface LinearStepInfo {
  icon?: string
  label: {
    label: string,
    labelStyle?: string,
    value: string,
    valueStyle?: string,
    color?: LinearStepStatusColor,
    fontSize?: LinearStepFontSize,
    row?: boolean,
    img?:boolean,
    type?: string,
    sublabel?: {
      text?: string
      style?: string
    }
  }
  clipboard?: boolean
  clipboardText?: string
  status?: {
    color: LinearStepStatusColor
    label: string
  },
  
}

export enum LinearStepStatusColor {
  GREEN = 'green',
  RED = 'red',
  YELLOW = 'yellow',
  BLUE = 'blue',
  PURPLE= 'purple',
}

export enum LinearStepFontSize {
  SMALL = 'small',
  LARGE = 'large',
  MAX_SMALL = 'max-small'
}
