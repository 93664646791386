import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Banner } from './banner.interfaces';

@Component({
  selector: 'sh-banner',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './banner.component.html',
  styleUrl: './banner.component.scss',
})
export class BannerComponent {
  @Input({required: true}) bannerInput: Banner


  cobroDigitalBanner(){
   return  this.bannerInput?.processor === 'RAPIPAGO' || this.bannerInput?.processor === 'COBRO_EXPRESS' || this.bannerInput?.processor === 'PAGO24' 
  }
}
