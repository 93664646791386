export interface IGetOperationResponse {
    operation: {
        id: number;
        amount: string;
        currencyCode: string;
        currencySymbol: string;
        status: string;
        errorUrl: string;
        okUrl: string;
        expirationDate: string;
    };
    merchant: {
        id: number;
        name: string;
    };
    processor: {
        id: number;
        name: string;
    }
    verificationResult: string;
}

export class GetOperationResponse {
    operation: {
        id: number;
        amount: string;
        currencyCode: string;
        currencySymbol: string;
        status: string;
        errorUrl: string;
        okUrl: string;
        expirationDate: string;
    };
    merchant: {
        id: number;
        name: string;
    };
    processor: {
        id: number;
        name: string;
    }
    verificationResult: string;

    constructor(response: IGetOperationResponse) {
        this.operation = response.operation
        this.merchant = response.merchant
        this.processor = response.processor
        this.verificationResult = response.verificationResult
    }
}

export interface ICreateDebinResponse {
    success: boolean;
    statusCode: number;
}

// statusCode 2 pendiente
// success false y statusCode 2 reintentar
// statuscode 6 es rechazado, redireccion a error
// si expirationDate se cumplio redirecciono a error