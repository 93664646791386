<div class="container">
            @if(linearSteps[0].label.row && linearSteps[0].label.img) {
            <div class="step">
                <div class="label" style="font-weight: 800;margin-bottom: 50px;" [ngClass]="linearSteps[0].label.fontSize">
                    <div style="display: flex; justify-content: space-evenly; align-items: center;">
                       
                            <span class="title highlight margin-title"  [innerHTML]="isDeuna ? titleDeuna : linearSteps[0].label.label"></span>
                    

                        <button [ngClass]="{'hidden': !copy}" style="width: 80px;height:25px;border-radius: 50px; background-color: #395AE8;" mat-flat-button (click)="copyToClipboard()">
                            <span style="display: flex;flex-direction: row; font-size: 10px; color: white !important;">
                               <img src="./assets/svg/copy.svg" style="margin-right: 5px;" alt="copy">
                                  Copiar
                            </span>
                        </button>
                    </div>
                    <img class="img-code"  style="margin-top: 1.3rem !important; " [src]="base64QRCode">

                    <div style="width: 100%;display: flex;justify-content: center;" *ngIf="isDeuna">
                        <button class="btn-download" mat-flat-button    style="color: white;">
                            <span class="span-btn">
                              PAGAR
                            </span>
                          </button>
                    </div>
                    
                </div>
            </div>
        }

        <div class="line-horizontal">
        </div>
    
   
    <div style="display: flex; flex-direction: row; justify-content: center; align-items: center; width: 100%;">
        @for (step of linearSteps; track $index) {
        @if(!step.label.row) {
        
        <div class="step">
            @if(step.icon) {
            <div class="step-icon">
                <img [src]="step.icon" alt="">
            </div>
            }
            @if ($index === 2) {
                <div class="label" [ngClass]="step.label.fontSize">
                    @if (!isDeuna) {
                        <span class="title" [innerHTML]="step.label.label"></span>
                        <div class="value">
                        <span [innerHTML]="step.label.value" [ngClass]="step.label.color"></span>
                    </div>
                    } @else {
                        <span class="title" [innerHTML]="titleTimer"></span>
                    <div class="" >
                        <span [innerHTML]="timerString" [ngClass]="classString"></span>
                    </div>
                }
                </div>
                
            }@else{
            <div class="label" [ngClass]="step.label.fontSize">
                <span class="title" [innerHTML]="step.label.label"></span>
                <div class="value">
                    <span [innerHTML]="step.label.value" [ngClass]="step.label.color"></span>
                </div>
            </div>
            }
            
            @if (step.status) {
            <div class="status">
                <span [innerHTML]="step.status.label">
                  
                </span>
            </div>
            }
        </div>

        @if ($index < linearSteps.length - 1) {
             <div class="line-container">
            <div class="line"></div>
            </div>
            }
        }   
    }
</div>




</div>


      
      