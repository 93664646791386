import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckoutPayinFormContainerComponent } from '@angular-monorepo/checkout-payin-form';
import { FormPayinService } from '@angular-monorepo/api-services';

@Component({
  selector: 'angular-monorepo-form-niubiz-yape',
  standalone: true,
  imports: [CommonModule,CheckoutPayinFormContainerComponent],
  templateUrl: './form-niubiz-yape.component.html',
  styleUrl: './form-niubiz-yape.component.scss',
  providers:[FormPayinService]
})
export class FormNiubizYapeComponent {}
