<mat-card [ngClass]="getClass()">
  @if (cardStructure.title) {
  <mat-card-title>
    <ng-content select="[slot=card_title]"></ng-content>
  </mat-card-title>
  }
  @if(cardStructure.content) {
  <mat-card-content>
    <ng-content select="[slot=card_content]"></ng-content>
  </mat-card-content>
  }
  @if(cardStructure.actions) {
  <mat-card-actions>
    <ng-content select="[slot=card_actions]"></ng-content>
  </mat-card-actions>
  }
</mat-card>
